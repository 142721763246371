<template>
	<v-expansion-panel-content>
		<template v-slot:header>
			<v-layout align-center justify-space-between row>
				<v-flex
					:class="{
						'font-weight-bold': open,
						'primary--text': open
					}"
					shrink
					text-truncate
					v-text="value.iban"
				/>
				<v-flex v-if="open && !readonly" shrink style="min-width: 105px">
					<w-btn-save :disabled="!isDirty || !isValid" flat :loading="isUpdating" :mini="$vuetify.breakpoint.smAndDown" @click.stop="updateBankAccount" />
					<w-btn-delete flat :loading="isDeleting" mini @click.stop="deleteBankAccount" />
				</v-flex>
			</v-layout>
		</template>
		<v-card>
			<v-card-text>
				<DetailedBankForm ref="form" v-model="copy" :readonly="readonly" :is-valid.sync="isValid" />
			</v-card-text>
		</v-card>
	</v-expansion-panel-content>
</template>

<script>
import { mapState } from 'vuex'
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/VendorSettings/VendorsSettingsModuleGuard'
export default {
	name: 'BankPanel',
	components: {
		DetailedBankForm: () => ({
			component: import('@/components/CustomersManager/CustomerDetails/Bank/DetailedBankForm')
		})
	},
	mixins: [CustomersManagerModuleGuard],
	props: {
		open: {
			default: false,
			required: true,
			type: Boolean
		},
		readonly: {
			default: false,
			required: false,
			type: Boolean
		},
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			copy: null,
			isDeleting: false,
			isUpdating: false,
			isValid: false
		}
	},
	computed: {
		...mapState({
			accountingFirm: state => state.accountingFirm.selected,
			vendor: state => state.company.selected
		}),
		isDirty: function () {
			if (!this.copy) {
				return false
			}
			const keys = Object.keys(this.value)
			if (Object.keys(this.copy).length > keys.length) {
				return true
			}
			for (let i = 0; i < keys.length; i++) {
				const key = keys[i]
				if (this.copy[key] != this.value[key]) {
					return true
				}
			}

			return false
		}
	},
	watch: {
		value: {
			handler: function () {
				this.createCopy()
			}
		}
	},
	mounted: function () {
		this.createCopy()
	},
	methods: {
		createCopy: function () {
			this.copy = { ...this.value }
			if (this.$refs.hasOwnProperty('form')) {
				this.$refs.form.reset()
			}
		},
		deleteBankAccount: function () {
			this.isDeleting = true

			this.$dialog
				.warning({
					text: this.$t('customer.bank.actions.are_you_sure'),
					title: this.$t('customer.bank.actions.delete'),
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				})
				.then(res => {
					if (res) {
						const accountingFirmId = this.accountingFirm.id
						return this.service.deleteBankAccount(accountingFirmId, this.vendorId, this.value.id).then(() => {
							this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('customer.bank.bank_deleted'))
							this.$emit('deleted', this.value)
						})
					}
				})
				.finally(() => {
					this.isDeleting = false
				})
		},
		updateBankAccount: function () {
			this.isUpdating = true
			const accountingFirmId = this.accountingFirm.id
			this.service
				.updateBankAccount(accountingFirmId, this.vendorId, this.value.id, this.copy)
				.then(bankAccount => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('customer.bank.bank_updated'))
					this.$emit('updated', bankAccount)
				})
				.finally(() => {
					this.isUpdating = false
				})
		}
	}
}
</script>
